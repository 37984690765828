export function createCheckoutSession(productId) {
  return fetch("/orders/create_checkout_session", {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      product_id: productId
    })
  }).then(function(result) {
    return result.json();
  });
};

export function changeTab() {
  var newActiveTabID = $("input[name=\"payment-selection\"]:checked").val();
  $(".paymentSelectionTab").removeClass("active");
  $("#tab-" + newActiveTabID).addClass("active");
};
