// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.

import "core-js/stable"
import "regenerator-runtime/runtime"
import "bootstrap"
//import "@fortawesome/fontawesome-free/js/fontawesome";
//import "@fortawesome/fontawesome-free/js/brands";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag "rails.png" %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

const fonts = require.context("../fonts", true)
const fontPath = (name) => fonts(name, true)

import jQuery from "jquery"

window.jQuery = jQuery;

window.App = {};

import * as utils from "./utils";

window.Utils = utils;

import * as products from "./app/products";

window.App.Products = products;

import * as registrations from "./app/registrations";

window.App.Registrations = registrations;

document.addEventListener("turbolinks:load", function() {
  $("[data-toggle=\"tooltip\"]").tooltip();
});


window.saveUserClickHandler = function(token) {      
  $('form#new_user').submit();
}    

window.disableMenuForUsersWithoutActivePlan = function() {
  const authenticated = document.body.dataset.authenticated === "true";
  const hasActivePlan = document.body.dataset.activeplan === "true";

  if (!authenticated || !hasActivePlan) {
    document.querySelectorAll('a[data-disable-if-active-plan="true"]').forEach(link => {
      link.addEventListener('click', function(event) {
        event.preventDefault();
      });
      link.classList.add('disabled');
    });
  }
}
  
document.addEventListener("turbolinks:load", function() {
  if(window.Utils.controller(document) == "events") {
    $("li.nav-item").find("a.events-link").addClass("active");
  } else if(window.Utils.controller(document) == "numbers") {
    $("li.nav-item").find("a.numbers-link").addClass("active");
  } else if(window.Utils.controller(document) == "number_sheets") {
    $("li.nav-item").find("a.number-sheets-link").addClass("active");
  } else if(window.Utils.controller(document) == "integrations") {
    $("li.nav-item").find("a.integrations-link").addClass("active");
  } else if(window.Utils.controller(document) == "notification_settings") {
    $("li.nav-item").find("a.notification-settings-link").addClass("active");
    $("form.notification-setting-form").find("select.notification-method-select").on("change", function() {
      let selectValue = this.value;
      let textInputs = $("form.notification-setting-form").find("input[type=text]");
      let submitInputs = $("form.notification-setting-form").find("input[type=submit]");

      textInputs.addClass("d-none").addClass("disabled");

      if(selectValue == "email") {
        $("form.notification-setting-form").find("input.email-field").removeClass("d-none").removeClass("disabled");
        submitInputs.removeClass("disabled");
      } else if(selectValue == "dialcode") {
        $("form.notification-setting-form").find("input.dialcode-field").removeClass("d-none").removeClass("disabled");
        submitInputs.removeClass("disabled");
      } else if(selectValue == "webhook-url") {
        $("form.notification-setting-form").find("input.webhook-url-field").removeClass("d-none").removeClass("disabled");
        submitInputs.removeClass("disabled");
      } else {
        textInputs.addClass("d-none").addClass("disabled");
        submitInputs.addClass("disabled");
      }
    });
  } else if(window.Utils.controller(document) == "users") {
    $("li.nav-item").find("a.user-link").addClass("active");
  } else if(window.Utils.controller(document) == "products") {
    $("li.nav-item").find("a.subscriptions-link").addClass("active");
    App.Products.changeTab();
    let form = $("form.product-subscribe-form");
    let submitButton = $(form).find("input.product-subscribe-button");

    form.on("submit", function(event) {
      event.preventDefault(); // Prevent the default form submission    

      let productId = $(this).find("input#product_id").val();
      let stripeKey = $(this).find("input#stripe_key").val();      

      App.Products.createCheckoutSession(productId).then(function(data) {
        // Call Stripe.js method to redirect to the new Checkout page
        var stripe = Stripe(stripeKey);
        stripe.redirectToCheckout({
          sessionId: data.sessionId
        }).then(function(result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        }).catch(function(error) {
          console.error("Error:", error);
        });
      });
    });

    let manageBillingButton = $("#manage-billing-button");
    manageBillingButton.click(function(event) {
      fetch("/orders/billing_portal", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data.url;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    });

  } else if(window.Utils.controller(document) == "registrations") {            

  } else {

  }

  window.disableMenuForUsersWithoutActivePlan();
});
