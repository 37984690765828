export function controller(d) {
  var controller = d.body.getAttribute('data-controller');

  return controller;
};

export function action(d) {
   var action = d.body.getAttribute('data-action');

   return action;
};
